/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/jquery@3.7.1/dist/jquery.min.js
 * - /npm/bootstrap@5.3.2/dist/js/bootstrap.min.js
 * - /npm/datatables.net@1.13.10/js/jquery.dataTables.min.js
 * - /npm/datatables.net-scroller@2.3.0/js/dataTables.scroller.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
